<template>
    <div class="container" :style="{'margin-top':statusBarHeight+'px'}">
        <header>
            <router-link
                v-if="!editing"
                :to="{ name: 'profileIndex' }"
                v-slot="{ navigate }"
                custom
            >
                <div class="back-button" @click="navigate">
                    <i class="icon-leftarrow"></i>
                    返回
                </div>
            </router-link>
            <div v-else class="back-button" @click="editing = false">
                <i class="icon-leftarrow"></i>
                返回
            </div>
            <div v-if="!editing" class="edit-button" @click="editing = true">
                編輯
            </div>
        </header>
        <show-profile v-if="!editing"></show-profile>
        <edit-profile v-else @save="editing = false"></edit-profile>
    </div>
</template>

<script>
import { lastPersonals } from "@/api";
import { mapMutations } from "vuex";
import EditProfile from "./profileComponents/editProfile.vue";
import showProfile from "./profileComponents/showProfile.vue";
export default {
    components: { showProfile, EditProfile },
    data: function () {
        return {
            editing: false,
            statusBarHeight:0,
        };
    },
    methods: {
        ...mapMutations({
            setMyProfileData: "profile/setMyProfileData",
        }),
    },
    created: function () {
        lastPersonals().then((res) => {
            console.log(res);
            this.setMyProfileData(res.data);
        });
        // ios头部内容适配问题
		if(this.$store.state.statusBarHeight > 0){
            this.statusBarHeight = parseInt(this.$store.state.statusBarHeight);
            this.statusBarHeights = this.statusBarHeights + parseInt(this.$store.state.statusBarHeight);
        }
    },
};
</script>

<style lang="scss" scoped>
.container {
    min-height: 100vh;
    padding: var(--safe-top) 0 var(--safe-bottom);
    background-color: $white;
    @include flexc-start-stretch;
    header {
        padding: 16px;
        @include flexr-between-center;
        font-size: 24px;
        font-weight: $semi-bold;
        color: $blue-300;
    }
}
</style>